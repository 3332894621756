import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tasks: [
    {
      id: 1,
      name: 'Farmer',
      img: './img/farmer_logo.gif',
      price: '50 000',
      link: 'https://t.me/byrealfarmer',
      channel_id: '@byrealfarmer' // добавлено поле channel_id
    },
    {
      id: 2,
      name: 'Фармер Чат',
      img: './img/chat.gif',
      price: '50 000',
      link: 'https://t.me/+vw93P2zdWqAxY2Ni',
      channel_id: -1001353243824 // добавлено поле channel_id
    },
    {
      id: 3,
      name: 'Крипту Фармим',
      img: './img/energy.gif',
      price: '50 000',
      link: 'https://t.me/cryptfarm',
      channel_id: '@cryptfarm' // добавлено поле channel_id
    },
    {
      id: 4,
      name: 'FarmTap',
      img: './img/Comp.gif',
      price: '50 000',
      link: 'https://t.me/FarmTapNews',
      channel_id: '@FarmTapNews' // добавлено поле channel_id
    },
    {
      id: 5,
      name: 'Движ Фармера',
      img: './img/dvizh.gif',
      price: '50 000',
      link: 'https://t.me/+TCFYmxCZefdzu8Se',
      channel_id: -1001277253787 // добавлено поле channel_id
    }
  ]
};

export const coinSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {}
});

export default coinSlice.reducer;
