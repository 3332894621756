import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../../config'; // Импортируйте базовый URL

const initialState = {
    count: 0,
    maximum: 1,
    able: 1,
    oneTap: 10,
    multitapPrice: 1000,
    energyPrice: 2100,
    skin: './img/coin-btn.png',
    skinPrice: 1000000,
    isChanged: false,
    isBuy: false,
    currentActive: 'secondSkin',
    localId: 'secondSkin',
    loading: false,
    error: null,
    autofarm: false,
    hasLoaded: false, // добавлено новое состояние
    tasks_id_done: [], // добавлено новое состояние
    referralCount: 0,
    status: 'idle',
};
export const fetchUserPoints = createAsyncThunk(
    'coin/fetchUserPoints',
    async (telegram_id, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/user/${telegram_id}`, {
                headers: {
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk для добавления кликов и обновления энергии
export const addClicks = createAsyncThunk(
    'coin/addClicks',
    async ({ telegram_id, clicks, energy }, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/clicks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                },
                body: JSON.stringify({ telegram_id, clicks, energy })
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk для пополнения энергии
export const replenishEnergy = createAsyncThunk(
    'coin/replenishEnergy',
    async ({ telegram_id, energyToAdd }, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/replenish-energy`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                },
                body: JSON.stringify({ telegram_id, energyToAdd })
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk для покупки multitap
export const buyMultitap = createAsyncThunk(
    'coin/buyMultitap',
    async ({ telegram_id, multitapPrice }, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/buy/multitap`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                },
                body: JSON.stringify({ telegram_id, multitapPrice })
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk для покупки energy
export const buyEnergy = createAsyncThunk(
    'coin/buyEnergy',
    async ({ telegram_id, price }, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/buy/energy`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                },
                body: JSON.stringify({ telegram_id, price })
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk для покупки autofarm
export const buyAutofarm = createAsyncThunk(
    'coin/buyAutofarm',
    async ({ telegram_id, price }, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/buy/autofarm`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                },
                body: JSON.stringify({ telegram_id, price })
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk для покупки скинов
export const buySkin = createAsyncThunk(
    'coin/buySkin',
    async ({ telegram_id, price, skin }, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/buy/skin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                },
                body: JSON.stringify({ telegram_id, price, skin })
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk для изменения скина
export const changeSkin = createAsyncThunk(
    'coin/changeSkin',
    async ({ telegram_id, skin }, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/change/skin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                },
                body: JSON.stringify({ telegram_id, skin })
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk для получения количества рефералов
export const fetchReferralCount = createAsyncThunk(
    'coin/fetchReferralCount',
    async (telegram_id, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/user/${telegram_id}/referrals`, {
                headers: {
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return data.referralCount;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk для проверки подписки и обновления задач
export const checkSubscriptionAndUpdateTask = createAsyncThunk(
    'coin/checkSubscriptionAndUpdateTask',
    async ({ telegram_id, channel_id, task_id, price }, { rejectWithValue }) => {
        try {
            const tgWebAppData = window.Telegram.WebApp.initData;
            console.log('Sending tgWebAppData:', tgWebAppData);
            const response = await fetch(`${API_BASE_URL}/api/check-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tgWebAppData}`,
                    'X-Pinggy-No-Screen': 'true',
                    'User-Agent': 'CustomUserAgent'
                },
                body: JSON.stringify({ telegram_id, channel_id, task_id, price })
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.error || 'Network response was not ok');
            }

            const data = await response.json();
            return { isMember: data.isMember, task_id };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const coinSlice = createSlice({
    name: 'coin',
    initialState,
    reducers: {
        increment: (state) => {
            if (state.able >= 10) {
                state.count += state.oneTap;
            }
        },
        increase: (state) => {
            if (state.able >= 10) {
                state.able -= 10;
            }
        },
        editSkin: (state, action) => {
            state.skin = action.payload;
        },
        buySkinSl: (state) => {
            state.isBuy = true;
        },
        setMaximum: (state) => {
            state.able = state.maximum;
        },
        editActive: (state, action) => {
            state.currentActive = action.payload;
        },
        editLocal: (state, action) => {
            state.localId = action.payload;
        },
        raiseEnergy: (state) => {
            if (state.able < state.maximum) {
                state.able += 1;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserPoints.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserPoints.fulfilled, (state, action) => {
                state.loading = false;
                state.count = action.payload.farm_coins;
                state.multitap = action.payload.multitap;
                state.oneTap = 10 + action.payload.multitap;
                state.maximum = action.payload.energy;
                state.energy = action.payload.energy;
                state.autofarm = action.payload.autofarm;
                state.current_skin = action.payload.current_skin;
                state.skin = action.payload.current_skin;
                state.skins = action.payload.skins;
                state.able = action.payload.able;
                state.hasLoaded = true; // Устанавливаем флаг hasLoaded в true после первой загрузки
                state.tasks_id_done = action.payload.tasks_id_done
            })
            .addCase(fetchUserPoints.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(addClicks.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addClicks.fulfilled, (state, action) => {
                state.loading = false;
                state.count = action.payload.farm_coins;
                state.able = action.payload.able;

            })
            .addCase(addClicks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(buyMultitap.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(buyMultitap.fulfilled, (state, action) => {
                state.loading = false;
                state.count = action.payload.farm_coins;
                state.multitap = action.payload.multitap;
            })
            .addCase(buyMultitap.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(buyEnergy.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(buyEnergy.fulfilled, (state, action) => {
                state.loading = false;
                state.count = action.payload.farm_coins;
                state.energy = action.payload.energy;
            })
            .addCase(buyEnergy.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(buyAutofarm.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(buyAutofarm.fulfilled, (state, action) => {
                state.loading = false;
                state.count = action.payload.farm_coins;
                state.autofarm = action.payload.autofarm;
            })
            .addCase(buyAutofarm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(buySkin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(buySkin.fulfilled, (state, action) => {
                state.loading = false;
                state.count = action.payload.farm_coins;
                state.skins = action.payload.skins;
            })
            .addCase(buySkin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(changeSkin.fulfilled, (state, action) => {
                state.skin = action.payload.current_skin;
            })
            .addCase(changeSkin.rejected, (state, action) => {
                state.error = action.error.message;
            })
            .addCase(checkSubscriptionAndUpdateTask.fulfilled, (state, action) => {
                if (action.payload.isMember) {
                    state.tasks_id_done.push(action.payload.task_id);
                }
            })
            .addCase(checkSubscriptionAndUpdateTask.rejected, (state, action) => {
                state.error = action.error.message;
            })
            .addCase(replenishEnergy.pending, (state) => {
                state.loading = true;
            })
            .addCase(replenishEnergy.fulfilled, (state, action) => {
                state.able = action.payload.able;
                state.loading = false;
            })
            .addCase(fetchReferralCount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchReferralCount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.referralCount = action.payload;
            })
            .addCase(fetchReferralCount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(replenishEnergy.rejected, (state) => {
                state.loading = false;
            });

    },
});

export const { increment, increase, editSkin, buySkinSl, editActive, editLocal, raiseEnergy, setMaximum } = coinSlice.actions;

export default coinSlice.reducer;
