import React, { useEffect, useState } from 'react';
import './App.css';
import Navigation from './components/Navigation/Navigation';
import Home from './pages/Home/Home';
import Shop from './pages/Shop/Shop';
import Tasks from './pages/Tasks/Tasks';
import Profile from './pages/Profile/Profile';
import {
  BrowserRouter as Router, Route, Routes
} from "react-router-dom";

function App() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(true);
  const [isLandscape, setIsLandscape] = useState(false);
  //551
  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();
    const disableZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    const checkDevice = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return true;
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
      }
      return false;
    };

    const checkOrientation = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    setIsMobileOrTablet(checkDevice());
    checkOrientation();

    window.addEventListener('resize', checkOrientation);
    window.addEventListener('touchmove', preventDefault, { passive: false });
    window.addEventListener('gesturestart', preventDefault);
    window.addEventListener('gesturechange', preventDefault);
    window.addEventListener('touchstart', disableZoom, { passive: false });

    document.addEventListener('copy', preventDefault);
    document.addEventListener('cut', preventDefault);
    document.addEventListener('paste', preventDefault);

    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('touchmove', preventDefault);
      window.removeEventListener('gesturestart', preventDefault);
      window.removeEventListener('gesturechange', preventDefault);
      window.removeEventListener('touchstart', disableZoom);

      document.removeEventListener('copy', preventDefault);
      document.removeEventListener('cut', preventDefault);
      document.removeEventListener('paste', preventDefault);
    };
  }, []);

  if (!isMobileOrTablet) {
    return (
      <div className="desktop-warning">
        <div className="anim_bg"></div>
        <h1>Desktop is boring.<br /> Play on your mobile.</h1>
        <img src="./img/qr-code.png" alt="QR Code" className="qr-code" />
      </div>
    );
  }

  if (isLandscape) {
    return (
      <div className="landscape-warning">
        <div className="anim_bg"></div>
        <h1>Rotate your device to portrait mode to play...</h1>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="anim_bg"></div>
      <Router>
        <div className="app_content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </div>
        <Navigation />
      </Router>
    </div>
  );
}

export default App;
